// groups of multiplied css like CSS3 for cross browsing

// for css3 transition
@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
      -ms-transition: $args;
       -o-transition: $args;
          transition: $args;
}
// example use
//.class-name { @include transition(all 0.5s ease); }


// for css3 transition delay
@mixin transition-delay($args...) {
  -webkit-transition-delay: $args;
     -moz-transition-delay: $args;
      -ms-transition-delay: $args;
       -o-transition-delay: $args;
          transition-delay: $args;
}
// example use
//.class-name { @include transition-delay(1s); }


// for css3 transform
@mixin transform($transform...) {
  -webkit-transform: $transform;
     -moz-transform: $transform;
      -ms-transform: $transform;
       -o-transform: $transform;
          transform: $transform;
}
// example use
//.class-name { @include transition(all 0.5s ease); }


// for css3 transform style
@mixin transform-style($transform-style...) {
  -webkit-transform: $transform-style;
     -moz-transform: $transform-style;
      -ms-transform: $transform-style;
       -o-transform: $transform-style;
          transform: $transform-style;
}
// example use
//.class-name { @include transform-style(preserve-3d); }


//transform origin
@mixin transform-origin ($origin) {
  moz-transform-origin: $origin;
     -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
-webkit-transform-origin: $origin;
        transform-origin: $origin;
}


// for css3 animation
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} { @content; }
     @-moz-keyframes #{$animation-name} { @content; }
      @-ms-keyframes #{$animation-name} { @content; }
       @-o-keyframes #{$animation-name} { @content; }
          @keyframes #{$animation-name} { @content; }
}

@mixin animation($str...) {
  -webkit-animation: #{$str};
     -moz-animation: #{$str};
      -ms-animation: #{$str};
       -o-animation: #{$str};
          animation: #{$str};
}
// for example use
// @include keyframes(animationName) {
//   0% { opacity: 1; }
//   90% { opacity: 0; }
// }
// .class-name {
//   @include animation('animationName 5s 3');
// }


@mixin animation-delay($animdelay) {
  -webkit-animation-delay: #{$animdelay};
     -moz-animation-delay: #{$animdelay};
      -ms-animation-delay: #{$animdelay};
       -o-animation-delay: #{$animdelay};
          animation-delay: #{$animdelay};
}

@mixin animation-duration($animDuration) {
  -webkit-animation-duration: #{$animDuration};
     -moz-animation-duration: #{$animDuration};
      -ms-animation-duration: #{$animDuration};
       -o-animation-duration: #{$animDuration};
          animation-duration: #{$animDuration};
}

@mixin animation-fill-mode($fillmode) {
  -webkit-animation-fill-mode: #{$fillmode};
     -moz-animation-fill-mode: #{$fillmode};
      -ms-animation-fill-mode: #{$fillmode};
       -o-animation-fill-mode: #{$fillmode};
          animation-fill-mode: #{$fillmode};
}
// @include animation-fill-mode(forwards);

@mixin animation-timing-function($timingFunction) {
  -webkit-animation-timing-function: $timingFunction;
     -moz-animation-timing-function: $timingFunction;
      -ms-animation-timing-function: $timingFunction;
       -o-animation-timing-function: $timingFunction;
          animation-timing-function: $timingFunction;
}
// @include animation-timing-function(cubic-bezier(0,0,0,0));

// for border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
// example use
//.class-name { @include border-radius(10px); }


// for input appearance
@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
     -moz-appearance: $appearance;
          appearance: $appearance;
}
// example use
//.class-name { @include appearance(none); }


// for user select
@mixin user-select($userselect) {
  -webkit-user-select: $userselect;
     -moz-user-select: $userselect;
      -ms-user-select: $userselect;
          user-select: $userselect;
}
// example use
//.class-name { @include user-select(none); }


// for pointer events
@mixin pointer-events($pointerevents) {
  -webkit-pointer-events: $pointerevents;
     -moz-pointer-events: $pointerevents;
      -ms-pointer-events: $pointerevents;
          pointer-events: $pointerevents;
}
// example use
//.class-name { @include pointer-events(none); }


// for box sizing
@mixin box-sizing($boxsizing) {
  -webkit-box-sizing: $boxsizing;
     -moz-box-sizing: $boxsizing;
      -ms-box-sizing: $boxsizing;
          box-sizing: $boxsizing;
}
// example use
//.class-name { @include pointer-events(none); }


// for placeholder
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} { @content; }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') { @content; }
  @include optional-at-root(':-moz-placeholder') { @content; }
  @include optional-at-root('::-moz-placeholder') { @content; }
  @include optional-at-root(':-ms-input-placeholder') { @content; }
}


// for opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
// example use
//.class-name { @include opacity(0.8); }


// for backface visibility
@mixin backface-visibility($backface) {
  -webkit-backface-visibility: $backface;
     -moz-backface-visibility: $backface;
      -ms-backface-visibility: $backface;
          backface-visibility: $backface;
}
// example use
// @include backface-visibility(hidden);


// for background linear gradient
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
// example use
//@include linear-gradient(hotpink, tomato);

// for REM font sizes
$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == "comma" or $separator == "space" {
    @return append($list, null, $separator);
  } 
  
  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  
  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    } @else if type-of($value) == "list" {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

// for REM font sizes
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin font-size($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

// html {
//   @include breakpoint(md) {
//     font-size: 12px;
//   };
// }
// h1 { font-size: rem(12px); width: rem(200px) }

// Gray scale
@mixin gray-scale($value) {
    -webkit-filter: #{"grayscale(#{$value})"};
    -moz-filter: #{"grayscale(#{$value})"};
    filter:#{"grayscale(#{$value})"};
}

// @include gray-scale(100%);

// Brightness
@mixin brightness($value) {
    -webkit-filter: #{"brightness(#{$value})"};
    -moz-filter: #{"brightness(#{$value})"};
    filter:#{"brightness(#{$value})"};
}

// @include brightness(100%);

//for sticky position
@mixin position($position) {
  -webkit-position: $position;
     -moz-position: $position;
      -ms-position: $position;
          position: $position;
}
// .class-name { @include position(sticky); }

// for responsive font
@mixin responsive-font-size($min-font-size, $max-font-size, $min-screen-width, $max-screen-width, $font-size-step: 0.1em) {
    font-size: $max-font-size;
    @media all and (max-width: $min-screen-width) {
        font-size: $min-font-size;
    }
    @if $font-size-step <= 0 {

    }
    @else {
        $min-scale-factor: $min-font-size / $min-screen-width / 1em * 1px;
        $max-scale-factor: $max-font-size / $max-screen-width / 1em * 1px;
        $screen-width: round($max-screen-width);
        $font-size: $max-font-size;
        @while $screen-width >= $min-screen-width {
            $progress: ($screen-width - $min-screen-width) / ($max-screen-width - $min-screen-width);
            $scale-factor: $min-scale-factor + $progress * ($max-scale-factor - $min-scale-factor);
            $font-size: $screen-width * $scale-factor / 1px * 1em;
            @media all and (max-width: $screen-width) {
                font-size: $font-size;
            }
            $screen-width-step: $font-size-step / $scale-factor / 1em * 1px;
            @if $screen-width-step < 1px {
                $screen-width-step: 1px;
            }
            $screen-width: round($screen-width - $screen-width-step);
        }
    }
}
// example use
// .class-name { @include responsive-font-size (12px, 14px, 640px, 1200px, 0px); }

// for responsive or so called media queries or breakpoints
// you can adjust it anytime depending on the max width of the content
@mixin breakpoint($point) {
  @if $point == maxwidth {
    @media (max-width: $maxWidth) { @content; }
  }
  @else if $point == xl {
    @media (max-width: 1200px) { @content; }
  }
  @else if $point == lg {
    @media (max-width: 992px) { @content; }
  }
  @else if $point == md {
    @media (max-width: 768px) { @content; }
  }
  @else if $point == sm {
    @media (max-width: 576px) { @content; }
  }
  @else if $point == xs {
    @media (max-width: 540px) { @content; }
  }
}
// example use
// @include breakpoint(tablet) {
//  / put your css here /
// }

// Example of lang parameter
// html[lang="en"] { css content here }

@mixin fontface($fontname, $fonturl, $fontweight:null, $fontstyle:null ) {
  @font-face{
    font-family: $fontname;
    src: url($fonturl+'.eot');
    src: url($fonturl+'.woff') format('woff'),
    url($fonturl+'.ttf') format('truetype'),
    url($fonturl+'.otf') format('opentype'),
    url($fonturl+'.svg') format('svg'),
    url($fonturl+'.eot?#iefix') format('embedded-opentype');
    @if($fontweight){
      font-weight: $fontweight;
    }@else{
      font-weight: normal;
    }

    @if($fontstyle){
      font-style: $fontstyle;
    }
    @else{
      font-style: normal;
    }
  }
}

// Description: Import local and customized webfonts

// ## Usage:
// @include fontface('FONT FACE NAME', '../directory/fontfilename')

// ## Output Sample:
// @font-face {
//    font-family: 'Helvetica';
//    src: url('fonts/Helvetica/HelveticaNeueCE-Thin.eot');
//    src: url('fonts/Helvetica/HelveticaNeueCE-Thin.woff') format('woff'),
//    url('fonts/Helvetica/HelveticaNeueCE-Thin.ttf') format('truetype'),
//    url('fonts/Helvetica/HelveticaNeueCE-Thin.svg#Riffic') format('svg'),
//    url('fonts/Helvetica/HelveticaNeueCE-Thin.eot?#iefix') format('embedded-opentype');
//    font-weight: normal;
//    font-style: normal;
//}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
// Usage
// .faded-text {
//   @include opacity(0.8);
// }
