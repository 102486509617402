.card-right {

    max-width: 300px;
    margin-left: auto;

    h4 {

        font-family: $defaultFont;
        font-weight: 500;
    }

    @include media-breakpoint-down(lg) {

        max-width: 100%;

        .cards {

            max-width: initial;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -15px;

            .card {

                flex-basis: calc(50% - 15px);
                margin-left: 15px;
                margin-bottom: 15px;

                .card-img {
                    
                    height: 160px;

                    img {

                        height: 160px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {


        .cards {

            .card {

                flex-basis: calc(50% - 15px);
            }
        }
    }

    @include media-breakpoint-down(sm) {


        .cards {

            .card {

                flex-basis: calc(100% - 15px);

                .card-img {
                    
                    height: 150px;

                    img {

                        height: 150px;
                    }
                }
            }
        }
    }
}

.card-big {

    position: relative;
    font-size: rem(16px);
    padding: 40px;
    background: $dwhite;

    @include media-breakpoint-down(lg) {

        padding: 25px;
    }
}

.cards {

    display: flex;
    flex-direction: column;
    max-width: 300px;

    &.card-right {

        margin-left: auto;
    }

}

.card {

    border: none;
    border-radius: 0;

    &.card-lg {

        h4 {

            font-size: rem(38px);
        }
    }

    &.card-bg {

        .card-body {

            padding: 12px 12px 25px;
            background: $lgreen;
        }
    }

    .card-body {

        padding: 20px 0 0;


        h4 {

            font-family: $montserrat;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }
}