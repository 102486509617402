.col-list {

  position: relative;
  padding: 15px 20px;
  margin-left: 24px;
  padding-bottom: 69%;
  background: url('../images/aboutus/folder.png') no-repeat center;
  background-size: 100% 100%;
  background-position: top;

  .col-list-inner {

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h4 {

    font-size: 0.9vw;
    font-weight: 500;
    height: 66px;
    padding: 15px;
    margin-bottom: 0;
    color: $green;
    margin-top: 0;
    max-width: 50%;
  }

  ul {

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 5px 50px;
  }

  li {

    list-style: disc;
    margin-left: 10px;
    font-size: 0.85vw;

    span {

      color: $white !important;
    }
  }

  @include media-breakpoint-down(xl) {

    h4 {

      height: 58px;
    }

    ul {

      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  @include media-breakpoint-down(lg) {

    h4 {

      height: 58px;
    }

    h4,
    li {

      font-size: 12px;
    }

    ul {

      padding: 0 20px;
    }
  }
}