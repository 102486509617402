.video-banner {

  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  overflow: hidden;

  .video-player {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.video-thumbs {

    img {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
    }

    &:before {

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: url('../images/page_template/play.svg') no-repeat center;
      background-size: 100px;
      cursor: pointer;
      z-index: 2;
    }
  
    &.play {
  
      img {
  
        visibility: hidden;
        z-index: -1;
      }
  
      iframe {
  
        visibility: visible;
        z-index: 1;
      }
  
      &:before {
  
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {

    margin-top: 60px;

    &.video-thumbs {

      &::before {

        background-size: 60px;
      }
    }
  }
}