/* Addresses margins handled incorrectly in IE 6/7. */
* { margin: 0; padding: 0; outline:none; }
html, body { font-size: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; margin:0; padding:0;}
body { font-size: 12px; line-height: 1.4; color: $defaultColor; }

/*
 * Addresses `font-family` inconsistency between `textarea` and other form
 * elements.
*/
html, body, button, input, select, textarea { font-family: $defaultFont; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

/***** [Reserve CSS Stylesheet] *****/
.opac a:hover { @include opacity(0.9); }
.invisible { visibility: hidden; } /* Hide visually and from screenreaders, but maintain layout */
.hidden { display: none !important; visibility: hidden; } /* Hide from both screenreaders and browsers: h5bp.com/u */

small { font-size: 80%; } /* Addresses inconsistent and variable font size in all browsers. */


/* Corrects `block` display not defined in IE 6/7/8/9 and Firefox 3. */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary { display: block; }

@media print,
    (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dppx) {
    // Style adjustments for high resolution devices
}

/* Corrects `inline-block` display not defined in IE 6/7/8/9 and Firefox 3. */
audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }

/*
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
*/
audio:not([controls]) { display: none; height: 0; }

/*
 * Addresses styling for `hidden` attribute not present in IE 7/8/9, Firefox 3,
 * and Safari 4.
 * Known issue: no IE 6 support.
*/

[hidden] { display: none; }

/* ==========================================================================
   Links
   ========================================================================== */

/*
 * Addresses `outline` inconsistency between Chrome and other browsers.
*/
a { outline:none; color: $defaultLinkColor; text-decoration:none; _noFocusLine: expression(this.hideFocus=true); cursor:pointer; }
a:active, a:hover , a:focus { outline:none; text-decoration:none; _noFocusLine: expression(this.hideFocus=true); }

/* ==========================================================================
   Typography
   ========================================================================== */

/* Addresses styling not present in IE 7/8/9, Safari 5, and Chrome. */
abbr[title] { border-bottom: 1px dotted; }

/* Addresses style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome. */
b, strong { font-weight: bold; }
blockquote { margin: 1em 40px; }

/* Addresses styling not present in Safari 5 and Chrome. */
dfn { font-style: italic; }

/* Addresses styling not present in IE 6/7/8/9. */
mark { background: #ff0; color: #000; }

/* Corrects font family set oddly in IE 6, Safari 4/5, and Chrome. */
code, kbd, pre, samp { font-family: monospace, serif; _font-family: 'courier new', monospace; font-size: 1em; }

/* Improves readability of pre-formatted text in all browsers. */
pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }

/* Addresses CSS quotes not supported in IE 6/7. */
q { quotes: none; }

/* Addresses `quotes` property not supported in Safari 4. */
q:before, q:after { content: ''; content: none; }


/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
*/
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }

/* ==========================================================================
   Lists
   ========================================================================== */

/*
 * Addresses paddings set differently in IE 6/7.
*/
menu, ol, ul { margin: 0; }

/*
 * Corrects list images handled incorrectly in IE 7.
 */
nav ul, nav ol , li { list-style: none; list-style-image: none; }

/* ==========================================================================
   Embedded content
   ========================================================================== */

/*
 * 1. Removes border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/*
 * Corrects overflow displayed oddly in IE 9.
 */
svg:not(:root) {
    overflow: hidden;
}

/* ==========================================================================
   Figures
   ========================================================================== */

/*
 * Addresses margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure { margin: 0; }

/* ==========================================================================
   Forms
   ========================================================================== */

/*
 * Corrects margin displayed oddly in IE 6/7.
*/
form { margin: 0; }

/* Define consistent border, margin, and padding. */
fieldset { border: 1px solid #c0c0c0;}

/*
 * 1. Corrects color not being inherited in IE 6/7/8/9.
 * 2. Corrects text not wrapping in Firefox 3.
 * 3. Corrects alignment displayed oddly in IE 6/7.
*/
legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/*
 * 1. Corrects font size not being inherited in all browsers.
 * 2. Addresses margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improves appearance and consistency in all browsers.
 */
button, input, select, textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
	outline:none;
	_noFocusLine: expression(this.hideFocus=true);
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
}
textarea {
  outline:none; resize:none;
}

/*
 * Addresses Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
*/
button, input { line-height: normal; }

/*
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Corrects inability to style clickable `input` types in iOS.
 * 3. Improves usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"], /* 1 */ input[type="reset"], input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/*
 * Re-set default cursor for disabled elements.
*/
button[disabled], input[disabled] { cursor: default; }

/*
 * 1. Addresses box sizing set to content-box in IE 8/9.
 * 2. Removes excess padding in IE 8/9.
 * 3. Removes excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
*/
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/*
 * 1. Addresses `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Addresses `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    @include box-sizing(content-box);
}

/*
 * Removes inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
*/
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

/* Removes inner padding and border in Firefox 3+. */
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

/*
 * 1. Removes default vertical scrollbar in IE 6/7/8/9.
 * 2. Improves readability and alignment in all browsers.
*/
textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/* ==========================================================================
   Tables
   ========================================================================== */

/* Remove most spacing between table cells. */
table { border-collapse: collapse; border-spacing: 0; }

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate.
 * Customize the background color to match your design.
 */
::-moz-selection { background: #b3d4fc; text-shadow: none; }
::selection { background: #b3d4fc; text-shadow: none; }

/* * A better looking default horizontal rule */
hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

/* Remove the gap between images and the bottom of their containers: h5bp.com/i/440 */
img { vertical-align: middle; }

/* Remove default fieldset styles. */
fieldset { border: 0; margin: 0; padding: 0; }

/*
 * Allow only vertical resizing of textareas.
 */
textarea { resize: vertical; }

/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a, a:visited { text-decoration: underline; }
    a[href]:after { content: " (" attr(href) ")"; }
    abbr[title]:after { content: " (" attr(title) ")"; }
    /*
     * Don't show links for images, or javascript/internal links
    */
    .ir a:after,
    a[href^="javascript:"]:after, a[href^="#"]:after { content: ""; }
    pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
    thead { display: table-header-group;  } /* h5bp.com/t */
    tr, img { page-break-inside: avoid; }
    img { max-width: 100% !important; }
    h2, h3 { page-break-after: avoid; }
}
