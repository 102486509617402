// include all imported fonts here even google fonts
@charset 'utf-8';
/** font face **/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');


.font-a { }
/** font face **/
