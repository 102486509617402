// ionclude all your related style here in the header, you can leave it empty if the page has no header
html, body { height:100%; min-height:100%; font-size: rem(15px); }

header {
	
	.header {

		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background: $black;
		color: $white;
		z-index: 5;

		&.transparent {

			background: none;

			@include media-breakpoint-down(lg) {

				background: $black;
			}
		}

		.dark-logo {

			display: none;
		}

		.light-logo {

			display: block;
		}

		.green-logo {

			display: none;
		}

		&.dark {

			color: $black;

			.light-logo {

				display: none;
			}

			.dark-logo {

				display: block;
			}

			.menu {

				li {

					a {

						color: $black;
					}
				}
			}

			.user {

				border-color: $black;

				.sns {

					color: $black;
				}
			}

			.logo {

				&:hover {
	
					.light-logo,
					.dark-logo {
	
						display: none;
					}
	
					.green-logo {
	
						display: block;
					}
				}
			}

			&.scrolled {

				color: $white;

				.light-logo {

					display: block !important;
				}

				.dark-logo {

					display: none;
				}

				.green-logo {
	
					display: none !important;
				}

				.menu {

					li {

						a {

							color: $white;
						}
					}
				}
				
				.user {

					border-color: $white;

					.sns {

						color: $white;
					}
				}
			}

			@include media-breakpoint-down(lg) {

				color: $white;

				.dark-logo {

					display: none;
				}

				.light-logo {

					display: block;
				}
			}
		}

		&.scrolled {

			background: $black !important;
		}

		.container {

			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 20px;
			padding-bottom: 20px;

			@include media-breakpoint-down(lg) {

				padding-top: 15px;
				padding-bottom: 15px;
			}
		}

		.header-right {

			display: flex;
			align-items: center;

			@include media-breakpoint-down(lg) {

				display: none;
			}
		}

		.menu {

			li {

				display: inline-block;

				a {

					position: relative;
					display: block;
					margin-left: 20px;
					text-transform: uppercase;
					color: $white;
					
					&:after {

						position: absolute;
						left: 0;
						bottom: -2px;
						width: 100%;
						height: 1px;
						content: '';
						border-bottom: 1px solid $green;
						@include transform(scale(0));
						@include transition(all 0.7s cubic-bezier(0.22, 0.61, 0.36, 1));
						@include transform-origin(left center);
					}

					&:hover,
					&.active {

						&::after {

							@include transform(scale(1));
						}
					}
				}
			}
		}

		.user {
			
			display: flex;
			align-items: center;
			margin-left: 20px;
			border-left: 1px solid $white;

			.sns {

				font-size: rem(20px);
				margin-left: 20px;
				color: $white;
			}

			.lang {

				cursor: pointer;
				margin-left: 20px;

				span {

					padding: 30px 0;
				}

				i {

					font-size: rem(14px);
					margin-left: 5px;
				}
			}

		}	

		.lang {

			position: relative;
		}

		.burger-menu {
			
			display: none;
			cursor: pointer;
			font-size: rem(28px);
			line-height: 0;

			i {

				height: 19px;
				overflow: hidden;
			}

			@include media-breakpoint-down(lg) {

				display: block;
			}
		}
	}
}
