.grid-img {

	position: relative;

	&.grid-flex {

		display: flex;
		flex-direction: column;
		height: 100%;
	}
	
	.gird-caption {

		display: flex;
		align-items: flex-end;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($black, 0.4);
		opacity: 0;
		@include transition(all 0.6s ease);

		span {

			display: inline-block;
			position: relative;
			font-size: rem(16px); 
			font-weight: 400;
			color: $white;

			&::after {

				position: absolute;
				left: 0;
				bottom: -2px;
				width: 100%;
				height: 1px;
				content: '';
				border-bottom: 1px solid $green;
				@include transform(scale(0));
				@include transition(all 0.6s cubic-bezier(0.22, 0.61, 0.36, 1));
				@include transform-origin(left center);
			}
		}

		h5 {

			margin-top: 15px;
			font-size: rem(22px);
			color: $white;
		}

		.grid-inner {

			margin: 50px;
		}
	}

	.grid-top-text {

		margin-bottom: 30px;
		flex-grow: 1;

		h5 {

			font-weight: 300;
			font-size: rem(18px);
		}

		strong {

			display: inline-block;
			font-weight: 500;
			font-size: rem(16px);
			margin-bottom: 10px;
			padding-bottom: 2px;
			padding-right: 10px;
			border-bottom: 2px solid $green;
		}
	}

	@include media-breakpoint-up(lg) {

		&:hover {

			.gird-caption {
	
				opacity: 1;
	
				span {
	
					&::after {
	
						@include transition-delay(0.3s);
						@include transform(scale(1));
					}
				}
			}
		}
	}
}

.grids {

	display: flex;
	margin-left: -20px;

	&.grid-center {

		justify-content: center;
	}

	.col-grid {

		flex-grow: 1;
		max-width: 270px;
		margin-left: 20px;
		flex-basis: 50%;

		&.col-grid-big {

			max-width: 567px;
		}

		.col-circle {

			position: relative;
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 100%;
			border-radius: 50%;

			&::after {

				position: absolute;
				top: -20px;
				left: -20px;
				width: calc(100% + 40px);
				height: calc(100% + 40px);
				content: '';
				border-radius: 50%;
				border: 1px solid $green;
			}
			
			h2 {

				font-size: rem(40px);
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				@include transform(translate(-50%, -50%));
				z-index: 2;
			}

			.col-circle-inner {

				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				padding: 30px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				z-index: 3;

				i {

					font-size: rem(35px);
				}

				a {

					font-size: rem(18px);
					font-weight: 600;
					display: inline-block;
					color: $white !important;
					margin-bottom: 5px;
				}

				.big {

					margin-top: 40px;
					margin-bottom: 40px;
				}

				span {
					
					font-size: rem(18px);
					margin-top: 40px;
					margin-bottom: 40px;
					display: inline-block;
					font-weight: 600;
				}

				@include media-breakpoint-down(md) {

					.big,
					span {

						font-size: rem(16px);
						margin-top: 25px;
						margin-bottom: 25px;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {

		flex-wrap: wrap;

		.col-grid {

			width: 100%;
			margin-bottom: 30px;

			&.col-grid-big {

				max-width: 270px;
			}
		}
	}

}

.grid2 {

	@include media-breakpoint-down(lg) {

		.col-grid {

			img {

				max-width: 150px;
			}
		}
	}
}