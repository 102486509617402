/** CSS for pace preloader **/
body:before,body:after { @include transition(opacity .4s ease-in-out); }
// use the commented out code below if you want to cover the whole page upon load
// z-index: 9999; position: fixed; top:0; left:0; width:100%; height:100%;

.pace {
  position: fixed;
  top: 0;
  left: 0;
  @include pointer-events(none);
  @include user-select(none);
  @include transition(all 0.5s ease);
  background: #010101;
  width: 100%;
  height: 100%;
  z-index: 1080;
  display: flex;
  justify-content: center;
  align-items: center;

}
.pace-inactive {
  @include opacity(0);
}
.pace .pace-activity {

  position: relative;
  width: 200px; 
  height: 200px;
  // border: 2px solid $coWhite;
  // border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pace .pace-progress {
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 39px;
  background: $white;
}

.pace .loading {

  position: relative;
  width: 150px;
  height: 39px;
  background: url('../images/page_template/loading_logo.png') no-repeat center;
  background-size: 100%;
  z-index: 2;
}

.pace .pace-progress-inner {

  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 37px;
  background: #555B5E;
  @include transition(all 0.5s ease);

}
/** CSS for pace preloader **/

