.mobile-menu {
  
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 350px;
    height: 100%;
    background: rgba($ddgreen, 0.9);
    z-index: 1060;
    @include transform(translateX(100%));
  
    &.easing {

      @include transition(all 0.3s ease);    
      
    }
  
    &.open {
  
      @include transform(translateX(0));
      
    }
  
    .sns {
  
      padding: 10px 20px;
      border-top: 1px solid $gray-200;
    }
  
    .menu-container {
    
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        height: 100%;
    }
  
    .menu-holder {
        
        position: relative;
        flex-grow: 1;
        flex-basis: 100%;
        padding-bottom: 65px;
    
        ul {
    
            padding-left: 0;
        }
    
        li {
    
            a,
            span {
        
                display: flex;
                align-items: center;
                font-size: rem(15px);
                padding: rem(12px 20px);
                color: $white;
                text-transform: uppercase;
        
                i {
        
                    font-size: rem(22px);
                    padding: 10px;
                    margin-top: -10px;
                    margin-bottom: -10px;
                    margin-right: -10px;
            
                    &:last-child {
            
                        margin-left: auto;
                    }
                }
            }
    
        }
    
    }
    
    .mobile-menu-header {

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 20px;
    }
  
    .menu-close {
    
        font-size: rem(30px);
        margin-left: auto;
        padding: 10px 0;
        border: none;
        background: none;
        color: $white;
    }

    .mobile-lang {
    
        font-size: rem(16px);
        margin-top: 20px;
        padding: 15px 20px;
    
        a {
    
            color: $white;
            margin-right: 15px;
        }
    }
  
    .mobile-menu-footer {
    
        
        margin-top: auto;
        padding: 20px;

        i {

            font-size: rem(30px);
        }
    
        a {
            
            font-size: rem(15px);
            color: $white;
        }

        .mobile-whatsapp {

            display: flex;
            align-items: center;

            span {

                margin-left: 15px;
            }
        }

        .mobile-sns {

            margin-top: 30px;

            a {
                margin-right: 15px;
            }

            i {

                font-size: rem(20px);
            }
        }
    
    }

    @include media-breakpoint-up(lg) {

        display: none;
    }
}
  
  